<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title>
            <template slot="title">
              Hay
              <span class="font-weight-bold">{{ ordersAll.length }}</span>
              pedidos por imprimir
            </template>
            <template slot="subtitle">
              Solo aparecen los pedidos para imprimir del día
              {{ general.date | moment('dddd DD') }} de
              {{ general.date | moment('MMMM - YYYY') }}
            </template>
            <template slot="button">
              <div style="width: 400px; float: right">
                <v-select
                  v-model="selectedSchedules"
                  :items="schedules"
                  :item-text="
                    e => {
                      return e.schedule
                    }
                  "
                  label="Filtar por horarios"
                  multiple
                  chips
                  attach
                  persistent-hint
                  solo
                  @change="changeSchedule"
                >
                  <template slot="selection" slot-scope="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span v-html="item"></span>
                    </v-chip>
                    <span v-if="index === 1" class="caption"> (+{{ selectedSchedules.length - 1 }} horarios) </span>
                  </template>
                </v-select>
              </div>
            </template>
          </app-title>
        </v-flex>
        <v-flex xs12 lg12 xl12>
          <v-card class="elevation-2">
            <v-data-table
              v-model="selected"
              select-all
              item-key="id"
              :headers="headers"
              :items="orders"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              class="elevation-0"
            >
              <template slot="items" slot-scope="props">
                <tr :active="props.selected">
                  <td>
                    <v-checkbox v-model="props.selected" color="primary" hide-details></v-checkbox>
                  </td>
                  <td>
                    <v-btn
                      color="info"
                      :to="{
                        name: 'orderIndex',
                        params: { id: props.item.id }
                      }"
                      target="_blank"
                      small
                    >
                      {{ props.item.id }}
                    </v-btn>
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    {{ props.item.date | moment('DD') }} de
                    {{ props.item.date | moment('MMMM - YYYY') }}
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    <Status :value="props.item.geo"></Status>
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    <Status :value="props.item.print"></Status>
                  </td>
                  <td class="text-xs-left" @click="props.selected = !props.selected">
                    <Status :value="props.item.message"></Status>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn :loading="loadingBtnSend" fab dark fixed bottom right color="accent" @click="sendPrints">
      <v-icon dark>send</v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import qz from 'qz-tray'
import appTitle from './useful/title.vue'
import Status from './useful/status.vue'
import { ORDERS_PRINTER } from '../config'

export default {
  name: 'PrintOrders',
  components: { appTitle, Status },
  metaInfo() {
    return {
      title: this.title
    }
  },
  data: () => ({
    selectedSchedules: [],
    printers: null,
    allDoc: [],
    title: 'Pedidos por imprimir',
    loadingBtnSend: false,
    selected: [],
    ordersAll: [],
    orders: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    headers: [
      {
        text: 'Pedido',
        align: 'left',
        sortable: false,
        value: 'order'
      },
      {
        text: 'Fecha de entrega',
        align: 'left',
        sortable: false,
        value: 'date'
      },
      {
        text: 'Geolocalizado',
        align: 'left',
        sortable: false,
        value: 'geo'
      },
      {
        text: 'Impreso',
        align: 'left',
        sortable: false,
        value: 'print'
      },
      {
        text: 'Mensaje',
        align: 'left',
        sortable: false,
        value: 'message'
      }
    ]
  }),
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    general() {
      return this.$store.getters.getGeneral
    },
    massive() {
      return this.$store.getters.getMassive
    },
    schedules() {
      const se = []
      const ss = this._.groupBy(this.ordersAll, 'schedule')
      this._.forEach(ss, function(v) {
        console.log(v[0].schedule)
        se.push(v[0].schedule)
      })

      return se
    }
  },
  watch: {
    massive(e) {
      if (e.send === 1) {
        setTimeout(() => {
          if (e.key !== undefined) {
            const urlmessage = `https://florista.cl/print_message.php?code=${this.selected[e.key].code}`
            window.open(urlmessage)
            this.selected[e.key].send = 2
            this.selected[e.key].print = true
            this.sendFirst()
            this.formatPrint(this.selected[e.key])
            this.setPrint(this.selected[e.key].id)
          } else {
            this.loadingBtnSend = false
            this.printOrder()
            this.selected = []
          }
        }, 2000)
      } else {
        this.selected = []
      }
    },
    'general.date': function(e) {
      this.getOrdersPrint()
    }
  },
  mounted() {
    this.getOrdersPrint()
    const self = this
    qz.websocket.connect().then(() => {
      qz.printers.find().then(printers => {
        self.printers = printers
      })
    })
  },
  methods: {
    async getOrdersPrint() {
      const res = await this.$http.get(ORDERS_PRINTER, {
        params: {
          date: this.general.date
        }
      })
      if (res.data.length > 0) {
        this.ordersAll = res.data
        this.orders = res.data
        this.selected = this.orders
        this.title = `Hay ${this.selected.length} pedidos por imprimir`
      }
    },
    async setPrint(id) {
      await this.$http.post(`${ORDERS_PRINTER}/${id}`)
    },
    addPrinter(d) {
      d.forEach(e => {
        this.allDoc.push(e)
      })
    },
    sendPrints() {
      if (this.selected.length > 0) {
        this.sendPrintsAction()
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Debe seleccionar pedidos para imprimir'
        })
      }
    },
    sendPrintsAction() {
      this.loadingBtnSend = true
      this._.forEach(this.selected, (item, index) => {
        this.selected[index].send = 1
      })
      this.sendFirst()
    },
    sendFirst() {
      const key = this._.findKey(this.selected, { send: 1 })
      this.$store.dispatch('changeMassive', {
        key,
        send: 1
      })
    },
    formatPrint(o) {
      const info = o.shipping_info !== '' ? `${o.shipping_info}\x0A` : ''
      let products = ''
      let destacado = ''
      let noPhoto = ''
      let express = ''
      const date = `${this.$moment.unix(o.date).format('dddd')} ${this.$moment
        .unix(o.date)
        .format('DD')} de ${this.$moment.unix(o.date).format('MMMM')}, ${this.$moment.unix(o.date).format('YYYY')}`
      let numProduct = 0
      o.products.forEach(e => {
        numProduct++
        let atributos = '\x0A'
        e.atributos.forEach(a => {
          atributos += `[${a.name}]\x0A`
        })
        products += `${e.quantity} x ${e.name} (P${e.id}) ${atributos}`
        if (e.message) {
          products += `${e.message}\x0A`
        }
        if (numProduct !== o.products.length) {
          products += '---\x0A'
        }
      })
      o.importants.forEach(e => {
        destacado += `${e.message}\x0A`
      })
      destacado += destacado !== '' ? '\x0A' + '------------------------------------------------' + '\x0A' + '\x0A' : ''
      noPhoto = o.shipping_hidden ? 'Foto de entrega NO debe ser con la persona recibiendo' + '\x0A' : ''
      if (o.priority.name === 'alta') {
        express = `Debe ser entregado antes de las ${o.priority.hour} hrs`
      }
      let data = [
        '\x1B' + '\x40', // init
        '\x1B' + '\x61' + '\x30', // right align
        '\x1B' + '\x21' + '\x30', // em mode on
        `${this.$options.filters.toPriceOut(o.id)}`,
        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
        '\x0A',
        '\x1B' + '\x4D' + '\x30', // normal text
        `${date}` + '\x0A',
        '\x1B' + '\x45' + '\x0D', // bold on
        `${o.schedule}`,
        '\x1B' + '\x45' + '\x0A', // bold off
        '\x0A' + '\x0A',
        '\x1B' + '\x4D' + '\x30', // normal text
        `${o.shipping_name}` + '\x0A',
        `${o.shipping_address}` + '\x0A',
        `${o.shipping_phone ? o.shipping_phone + '\x0A' : ''}`,
        `${info}`,
        noPhoto ? '\x1B' + '\x45' + '\x0D' : '',
        `${noPhoto}`,
        noPhoto ? '\x1B' + '\x45' + '\x0A' : '',
        express ? '\x1B' + '\x45' + '\x0D' : '',
        `${express}`,
        express ? '\x1B' + '\x45' + '\x0A' : '',
        '------------------------------------------------' + '\x0A' + '\x0A',
        `${products}`,
        '\x0A' + '------------------------------------------------' + '\x0A' + '\x0A',
        !o.shipping_type ? `${o.username}` + '\x0A' : '',
        !o.shipping_type ? `+${o.phone}` + '\x0A' + '\x0A' : 'ENVIO ANONIMO' + '\x0A' + '\x0A',
        '------------------------------------------------' + '\x0A',
        `${o.mensaje.replace(/<[^>]*>?/gm, '')}` + '\x0A' + '\x0A',
        '------------------------------------------------' + '\x0A' + '\x0A',
        `${destacado}`,
        '\x1B' + '\x61' + '\x31', // center align
        '\x1B' + '\x21' + '\x30', // em mode on
        `${o.shipping_name}` + '\x0A',
        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
        '\x1B' + '\x4D' + '\x30', // normal text
        `${o.shipping_address}` + '\x0A',
        `${info}`,
        '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A', // saltos de linea antes de cut
        '\x1D' + '\x56' + '\x00', // full cut (new syntax)
        '\x10' + '\x14' + '\x01' + '\x00' + '\x05'
      ]
      data = data.map(e => {
        return e.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      })
      this.addPrinter(data)
    },
    printOrder() {
      const printer = this.settings.printer
      const config = qz.configs.create(printer, { encoding: 'ISO-8859-1' })
      qz.print(config, this.allDoc)
      this.allDoc = []
    },
    changeSchedule() {
      const selected = []
      this.selected = []
      this.orders.forEach(e => {
        const k = this._.findIndex(this.selectedSchedules, function(o) {
          return o == e.schedule
        })
        if (k >= 0) {
          selected.push(e)
        }
      })
      this.selected = selected
    }
  }
}
</script>
